import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { SectionHeading } from '../components/Aggregators/SectionHeading'

import { space, mediaQueries, gradient, boxShadows, palette, fontSizes, mainOffset } from '../utils/tokens'
import Container from '../components/Container'
import { css } from 'styled-components'
import GatsbyImage from 'gatsby-image'

const GuideCard = ({ image, title }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 100%;
        max-width: 300px;
        height: 100%;
        border-radius: 10px;
        box-shadow: ${boxShadows.card};
        overflow: hidden;
      `}
    >
      <div
        css={css({
          position: `relative`,
          width: `100%`,
        })}
      >
        {image?.fluid && (
          <GatsbyImage
            fluid={image.fluid}
            alt={image.title}
            css={css`
              width: 100%;
              height: 100%;
            `}
          />
        )}
        <div
          css={css`
            background-image: ${gradient.primary};
            opacity: 0.8 !important;
            position: absolute !important;
            height: 100% !important;
            width: 100% !important;
            left: 0px !important;
            bottom: 0px !important;
          `}
        />
      </div>
      <span
        css={css`
          text-align: left;
          padding: ${space[3]}px ${space[3]}px;
          color: white;
          z-index: 1;
          font-size: ${fontSizes[2]};
          font-weight: bold;
          color: ${props => props.theme.colors.base};
          width: 100%;
          margin-bottom: 0;
        `}
      >
        {title}
      </span>
    </div>
  )
}


const Guides = ({ data }) => {
  const {
    title,
    metaTitle,
    metaDescription,
    hero,
    slug
  } = data.contentfulPage

  const guides = data.allContentfulPage.edges

  const postNode = {
    title: metaTitle,
    metaDescription: metaDescription,
  }

  return (
    <Layout>
      <Helmet>
        <title>{title} | PrintAWorld</title>
      </Helmet>
      <SEO pageSEO pagePath={slug} postNode={postNode} />
      <main
        css={css({
          paddingTop: `${mainOffset}`,
          [mediaQueries.lg]: {
            paddingTop: 0,
          },
        })}
      >
        <section>
          <Container>
            <div
              css={css({
                textAlign: `center`,
                padding: `${space[4]}px 0 `,
                [mediaQueries.lg]: {
                  padding: `${space[5]}px 0 `,
                },
              })}
            >
              <h1
                css={css({
                  fontWeight: `bold`,
                  [mediaQueries.lg]: {
                    fontSize: fontSizes[5],
                  },
                })}
              >
                {hero.title}
              </h1>
            </div>
            <SectionHeading title="3D Printing" />
            <div
              css={css`
                display: grid;
                grid-gap: ${space[4]}px;
                margin-bottom: 2rem;
                justify-items: center;
                grid-template-columns: 1fr;

                ${mediaQueries.lg} {
                  grid-gap: ${space[4]}px;
                  margin-bottom: ${space[4]}px;
                  justify-items: flex-start;
                  grid-template-columns: repeat(4, 1fr);
                }
              `}
            >
              {guides.map(({ node }, id) => {
                return (
                  <Link
                    key={id}
                    to={node.fields.path}
                    css={css({
                      height: `100%`,
                      display: `block`,
                      textDecoration: `none`,
                      '&:hover': {
                        '> * span': {
                          color: palette.orange[60],
                        },
                      },
                      [mediaQueries.lg]: {
                        width: `100%`,
                      },
                    })}
                  >
                    <GuideCard
                      key={node.id}
                      image={node.hero?.block?.image}
                      title={node.title}
                    />
                  </Link>
                )
              })}
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  )
}

export default Guides

export const GuidesQuery = graphql`
  query {
    contentfulPage(slug: { eq: "guides" }) {
      title
      slug
      hero {
        ... on Node {
          __typename
          ... on ContentfulHero {
            title
            backgroundPhoto {
              title
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
      metaTitle
      metaDescription {
        internal {
          content
        }
      }
    }
    allContentfulPage(filter: { template: { eq: "guides" } }) {
      edges {
        node {
          title
          hero {
            ... on Node {
              __typename
              ... on ContentfulHeroWithBlock {
                ...HeroWithBlock
              }
              ... on ContentfulHero {
                title
                backgroundPhoto {
                  title
                  fluid {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
            }
          }
          fields {
            path
          }
        }
      }
    }
  }
`