import React from 'react'
import { css } from 'styled-components'
import {
  space,
  fontSizes,
  palette,
  mediaQueries,
} from '../../utils/tokens'

export const SectionHeading = ({ title }) => {
  return (
    <div
      css={css({
        marginLeft: `${space[3]}px`,
        marginRight: `${space[3]}px`,
        paddingBottom: `${space[3]}px`,
        borderBottom: `1px solid ${palette.grey[40]}`,
        marginBottom: `${space[4]}px`,
        [mediaQueries.lg]: {
          marginLeft: 0,
          marginRight: 0,
        },
      })}
    >
      <h2
        css={css({
          textTransform: `uppercase`,
          fontSize: fontSizes[3],
          fontWeight: `bold`,
          textAlign: `center`,
          [mediaQueries.lg]: {
            textAlign: `left`,
          }
        })}
      >
        {title}
      </h2>
    </div>
  )
}